<template>
  <div class="center" style="background-color: white;">
    <div class="m-5">
      <p :class="`mt-20 font-bold color-purple ${isMobile ? 'font-20' : 'font-30'}`">{{ $t('alert_success_buy_ticket') }}</p>
      <p :class="`my-20 ${isMobile ? 'font-15' : 'font-20'}`">{{ message }}</p>
      <div class="d-inline mr-10" v-if="platform === 'Desktop' || platform === 'iOS'">
        <a :href="APP_STORE_URL" target="_blank">
          <img class="store-image" :src="assets.app_store" :height="isMobile ? 40 : 60" />
        </a>
      </div>
      <div class="d-inline" v-if="platform === 'Desktop' || platform === 'Android'">
        <a :href="GOOGLE_PLAY_URL" target="_blank">
          <img class="store-image" :src="assets.google_play" :height="isMobile ? 40 : 60" />
        </a>
      </div>
      <div class="my-20">
        <img class="col-xl-4 col-lg-6 col-md-12 col-sm-12" :src="assets.ticket_box" />
      </div>
    </div>
  </div>
</template>

<script>
import { getDateStringFromTimestamp, getDiffFromTimestamp, getLocalStorageInfo, getPlatform } from '../../../functions';

import google_play from '@/assets/images/google_play.png';
import app_store from '@/assets/images/app_store.png';
import ticket_box from '@/assets/images/ticket_box.jpg';

export default {
  name: 'DownloadApp',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    myInfo() {
      return this.$store.state.myInfo;
    }
  },
  data() {
    return {
      assets: {
        google_play,
        app_store,
        ticket_box
      },
      message: '',
      platform: getPlatform()
    };
  },
  mounted() {
    if (this.myInfo) {
      this.setMessage(this.myInfo.email, this.myInfo.userName);
    } else {
      const userInfo = getLocalStorageInfo('eventboxGuestInfo');
      if (userInfo) {
        if (userInfo.userType === this.USER_NORMAL) {
          this.setMessage(userInfo.email, userInfo.userName);
        } else if (userInfo.userType === this.USER_GUEST) {
          if (getDiffFromTimestamp(userInfo.createdAt) > 30 * 60 * 1000) { // If the guest user has been created before 30 mins
            this.message = this.$t('desc_you_already_registered_with_email_temporary_password_sent_at_', [userInfo.email, getDateStringFromTimestamp(userInfo.createdAt)]);
          } else {
            this.setMessage(userInfo.email, '');
          }
        } else {
          this.message = this.$t('alert_unexpected_error');
        }
      }
    }
    localStorage.setItem('eventboxGuestInfo', '');
  },
  methods: {
    setMessage(email, userName) {
      if (email && userName) {
        this.message = this.$t('desc_login_app_with_email_or_username', [email, userName]);
      } else if (userName) {
        this.message = this.$t('desc_login_app_with_username', [userName]);
      } else if (email) {
        this.message = this.$t('desc_sent_temporary_password_to_email', [email]);
      }
    }
  }
};
</script>